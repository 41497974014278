import _ from "lodash";
import gql from "graphql-tag";
import React, {useEffect, useState} from "react";
import {DataService} from "@vendure/admin-ui/core";
import {Card, useDetailComponentData, useInjector} from "@vendure/admin-ui/react";
import {ID, Product, ProductVariant} from "@vendure/core";

import {Codegen, queryResultToPromise} from "../";

const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($id: ID!) {
    productVariant(id: $id) {
      id
      productId
      stockLevels {
        stockOnHand
        stockAllocated
      }
      soldUnits
      soldUnitsSinceLastStart
    }
  }
`;

const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      customFields {
        startedAt
        closedAt
      }
    }
  }
`;

const getProductVariant = async (id: ID, dataService: DataService): Promise<Codegen.ProductVariant> => {
  const queryResult = dataService.query<{ productVariant: Codegen.ProductVariant }, {
    id: ID
  }>(GET_PRODUCT_VARIANT, {id});

  const result = await queryResultToPromise(queryResult);

  return result.productVariant;
};

const getProduct = async (id: ID, dataService: DataService): Promise<Product> => {
  const queryResult = dataService.query<{ product: Product }, {
    id: ID
  }>(GET_PRODUCT, {id});

  const result = await queryResultToPromise(queryResult);

  return result.product;
};

const getStockOnHand = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, "stockOnHand");
};

const getSaleableAmount = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, stockLevel =>
    Math.max(0, stockLevel.stockOnHand - stockLevel.stockAllocated),
  );
};

const getStockAllocated = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, "stockAllocated");
};

const formatDate = (date: string | undefined) => {
  if (!date) {
    return "-";
  }

  return new Date(date).toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getFormattedDurationDays = (startedAt: string | undefined, closedAt: string | undefined) => {
  if (!startedAt || !closedAt) {
    return "-";
  }

  const MS_DAY = 1000 * 60 * 60 * 24;

  const startedAtDate = new Date(startedAt);
  const closedAtDate = new Date(closedAt);

  const durationDays = Math.round((closedAtDate.getTime() - startedAtDate.getTime()) / MS_DAY);

  return durationDays === 1 ? "1 Tag" : `${durationDays} Tage`;
};

export const ProductVariantInfo = () => {
  const dataService = useInjector(DataService);
  const {entity} = useDetailComponentData<ProductVariant>();
  const [productVariant, setProductVariant] = useState<Codegen.ProductVariant | null>(null);
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    if (entity) {
      getProductVariant(entity.id, dataService).then(setProductVariant);
    }
  }, [entity]);

  useEffect(() => {
    if (productVariant) {
      getProduct(productVariant.productId, dataService).then(setProduct);
    }
  }, [productVariant]);

  if (!productVariant || !product) {
    return null;
  }

  const productCustomFields = product.customFields as Codegen.ProductCustomFields;

  return (
    <Card title="Verkaufszahlen im letzten Aktionszeitraum">
      <div className="sales-figures">
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr className="heading-row">
                <th className="cell-content left">Beginn</th>
                <th className="cell-content left">Ende</th>
                <th className="cell-content left">Dauer</th>
                <th className="cell-content placeholder"></th>
                <th className="cell-content left">Verkauft</th>
                <th className="cell-content left">Gesamt VK</th>
                <th className="cell-content left">Bestand</th>
                <th className="cell-content left">Reserviert</th>
                <th className="cell-content left">Verkäuflich</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="cell-content left">{formatDate(productCustomFields.startedAt)}</td>
                <td className="cell-content left">{formatDate(productCustomFields.closedAt)}</td>
                <td className="cell-content left">{getFormattedDurationDays(productCustomFields.startedAt, productCustomFields.closedAt)}</td>
                <td className="cell-content placeholder"></td>
                <td className="cell-content left">{productVariant.soldUnitsSinceLastStart ?? "-"}</td>
                <td className="cell-content left">{productVariant.soldUnits}</td>
                <td className="cell-content left">{getStockOnHand(productVariant)}</td>
                <td className="cell-content left">{getStockAllocated(productVariant)}</td>
                <td className="cell-content left">{getSaleableAmount(productVariant)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};
